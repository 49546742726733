/**
 * Disabled warnings about missing/unavailable storages
 */
export function disableWarnings() {
    noWarnings = true;
}
/**
 * If set to true, no warning will be emitted if the requested Storage is not found.
 * This option can be useful when the lib is used on a server.
 */
let noWarnings = false;
/**
 * List of storages where the warning have already been displayed.
 */
const alreadyWarnFor = [];
/**
 * Add a log to indicate that the requested Storage have not been found.
 * @param {string} storageName
 */
const warnStorageNotFound = (storageName) => {
    var _a;
    const isProduction = typeof process !== "undefined" && ((_a = process.env) === null || _a === void 0 ? void 0 : _a.NODE_ENV) === "production";
    if (!noWarnings &&
        alreadyWarnFor.indexOf(storageName) === -1 &&
        !isProduction) {
        let message = `Unable to find the ${storageName}. No data will be persisted.`;
        if (typeof window === "undefined") {
            message +=
                "\n" +
                    "Are you running on a server? Most of storages are not available while running on a server.";
        }
        console.warn(message);
        alreadyWarnFor.push(storageName);
    }
};
/**
 * Make a store persistent
 * @param {Writable<*>} store The store to enhance
 * @param {StorageInterface} storage The storage to use
 * @param {string} key The name of the data key
 */
export function persist(store, storage, key) {
    const initialValue = storage.getValue(key);
    if (null !== initialValue) {
        store.set(initialValue);
    }
    if (storage.addListener) {
        storage.addListener(key, (newValue) => {
            store.set(newValue);
        });
    }
    store.subscribe((value) => {
        storage.setValue(key, value);
    });
    return Object.assign(Object.assign({}, store), { delete() {
            storage.deleteValue(key);
        } });
}
function getBrowserStorage(browserStorage, listenExternalChanges = false) {
    const listeners = [];
    const listenerFunction = (event) => {
        const eventKey = event.key;
        if (event.storageArea === browserStorage) {
            listeners
                .filter(({ key }) => key === eventKey)
                .forEach(({ listener }) => {
                let value = event.newValue;
                try {
                    value = JSON.parse(event.newValue);
                }
                catch (e) {
                    // Do nothing
                    // use the value "as is"
                }
                listener(value);
            });
        }
    };
    const connect = () => {
        if (listenExternalChanges &&
            typeof window !== "undefined" &&
            (window === null || window === void 0 ? void 0 : window.addEventListener)) {
            window.addEventListener("storage", listenerFunction);
        }
    };
    const disconnect = () => {
        if (listenExternalChanges &&
            typeof window !== "undefined" &&
            (window === null || window === void 0 ? void 0 : window.removeEventListener)) {
            window.removeEventListener("storage", listenerFunction);
        }
    };
    return {
        addListener(key, listener) {
            listeners.push({ key, listener });
            if (listeners.length === 1) {
                connect();
            }
        },
        removeListener(key, listener) {
            const index = listeners.indexOf({ key, listener });
            if (index !== -1) {
                listeners.splice(index, 1);
            }
            if (listeners.length === 0) {
                disconnect();
            }
        },
        getValue(key) {
            let value = browserStorage.getItem(key);
            if (value !== null && value !== undefined && value !== "undefined") {
                try {
                    value = JSON.parse(value);
                }
                catch (e) {
                    // Do nothing
                    // use the value "as is"
                }
            }
            if (value === "undefined") {
                return undefined;
            }
            return value;
        },
        deleteValue(key) {
            browserStorage.removeItem(key);
        },
        setValue(key, value) {
            if (value === undefined) {
                browserStorage.setItem(key, "undefined");
                return;
            }
            browserStorage.setItem(key, JSON.stringify(value));
        },
    };
}
/**
 * Storage implementation that use the browser local storage
 * @param {boolean} listenExternalChanges - Update the store if the localStorage is updated from another page
 */
export function localStorage(listenExternalChanges = false) {
    if (typeof window !== "undefined" && (window === null || window === void 0 ? void 0 : window.localStorage)) {
        return getBrowserStorage(window.localStorage, listenExternalChanges);
    }
    warnStorageNotFound("window.localStorage");
    return noopStorage();
}
/**
 * Storage implementation that use the browser session storage
 * @param {boolean} listenExternalChanges - Update the store if the sessionStorage is updated from another page
 */
export function sessionStorage(listenExternalChanges = false) {
    if (typeof window !== "undefined" && (window === null || window === void 0 ? void 0 : window.sessionStorage)) {
        return getBrowserStorage(window.sessionStorage, listenExternalChanges);
    }
    warnStorageNotFound("window.sessionStorage");
    return noopStorage();
}
/**
 * Storage implementation that do nothing
 */
export function noopStorage() {
    return {
        getValue() {
            return null;
        },
        deleteValue() {
            // Do nothing
        },
        setValue() {
            // Do nothing
        },
    };
}
function noopSelfUpdateStorage() {
    return {
        addListener() {
            // Do nothing
        },
        removeListener() {
            // Do nothing
        },
        getValue() {
            return null;
        },
        deleteValue() {
            // Do nothing
        },
        setValue() {
            // Do nothing
        },
    };
}
