import { get } from "svelte/store";
import { settingsStore } from "../store/settings";
import { log } from "../util/utils";
export async function jsonRpc(id, method, request) {
    // request.requestHeader = { accessToken: get(settingsStore.token) };
    const httpRequest = {
        method: "POST"
    };
    if (method != null) {
        httpRequest["body"] = JSON.stringify({
            id,
            jsonrpc: "2.0",
            method,
            params: {
                request,
            },
        });
    }
    if (settingsStore.productive) {
        httpRequest["headers"] = {
            "Authorization": "Basic " + btoa("ui:" + get(settingsStore.token))
        };
    }
    log.debug({ httpRequest });
    try {
        var response = await fetch(settingsStore.backendUrl, httpRequest);
        if (response.ok) {
            var json = await response.json();
            if (json.error != null) {
                throw json.error;
            }
            return json.result;
        }
        else {
            throw response;
        }
    }
    catch (error) {
        throw error;
    }
}
