import { jsonRpc } from "./jsonrpc";
import { log, sleep } from "../util/utils";
import { settingsStore } from "../store/settings";
import { get } from "svelte/store";
let call = 1;
settingsStore.token.subscribe(token => {
    log.debug("validating token", token);
    heartbeat()
        .then(() => {
        log.info("token validated");
        settingsStore.updateTokenValidity(true);
    })
        .catch((error) => {
        log.debug(error);
        settingsStore.updateTokenValidity(false);
    });
});
export async function mocked() {
    if (get(settingsStore.mockApiCalls)) {
        log.debug("mocking call");
        let mockDelayInSeconds = get(settingsStore.delayApiCallsInSeconds);
        if (mockDelayInSeconds > 0) {
            await sleep(mockDelayInSeconds * 1000);
        }
        return true;
    }
    return false;
}
export function getId(request, prefix) {
    if (prefix != null) {
        return prefix + "-" + request.address.name + "-" + call++;
    }
    return request.address.name + "-" + call++;
}
export function getId2(request, prefix) {
    if (prefix != null) {
        return prefix + "-" + request.name + "-" + call++;
    }
    return request.name + "-" + call++;
}
export async function heartbeat() {
    if (await mocked()) {
        return;
        // throw new Error("token invalid");
    }
    return jsonRpc("heartbeat-" + call++, "com.seifrox.micro.heartbeat.api.HeartbeatRequest", {});
}
export async function socketAvailable(request) {
    if (await mocked()) {
        return true;
    }
    return jsonRpc(getId(request, "socketAvailable"), "com.seifrox.micro.utils.net.api.SocketAvailableRequest", request);
}
export async function wakeOnLan(request) {
    if (await mocked()) {
        return;
    }
    return jsonRpc(getId(request, "wakeOnLan"), "com.seifrox.micro.utils.net.api.WakeOnLanRequest", request);
}
export async function getDeviceStatus(request) {
    if (await mocked()) {
        return;
    }
    return jsonRpc(getId2(request, "getDeviceStatus"), "com.seifrox.micro.device.api.GetDeviceStatusRequest", request);
}
export async function devicePowerOn(request) {
    if (await mocked()) {
        return;
    }
    return jsonRpc(getId2(request, "devicePowerOn"), "com.seifrox.micro.device.api.DevicePowerOnRequest", request);
}
export async function devicePowerOff(request) {
    if (await mocked()) {
        return;
    }
    return jsonRpc(getId2(request, "devicePowerOff"), "com.seifrox.micro.device.api.DevicePowerOffRequest", request);
}
// export async function power(request: DeviceRequest): Promise<void> {
//   if (await mocked()) {
//     return Promise.resolve();
//   }
//   return jsonRpc(getId(request, "power"), "com.seifrox.micro.tv.api.TvPowerRequest", request);
// }
