<script lang="ts">
    import { Overlay } from "svelte-materialify";

    import FabButton from "./FabButton.svelte";
    import { tvStore, TvInputSource, INPUT_SOURCES } from "../store/tv";

    const inputSource = tvStore.getInputSource();
    const isChangingSource = tvStore.isChangingSource();

    let icon = INPUT_SOURCES.UNKOWN.icon;
    let color = INPUT_SOURCES.UNKOWN.color;
    let allowClicks = true;
    $: {
        let current = INPUT_SOURCES[$inputSource];
        if (current != null) {
            icon = current.icon;
            if ($isChangingSource) {
                color = "grey";
                allowClicks = false;
            } else {
                color = current.color;
                allowClicks = true;
            }
        }
    }
    let showOverlay = false;

    function setInput(inputSource: TvInputSource) {
        allowClicks = false;
        tvStore.setInputSource(inputSource);
    }
</script>

{#if allowClicks}
    <FabButton {icon} class={color} on:click={() => (showOverlay = true)} />
{:else}
    <FabButton {icon} class={color} />
{/if}
<Overlay absolute opacity={1} active={showOverlay} on:click={() => (showOverlay = false)}>
    <div style="align: right;">
        {#each Object.entries(INPUT_SOURCES) as [key, value], index (key)}
            {#if value.selectable}
                <div class="d-flex justify-end">
                    <FabButton icon={value.icon} on:click={() => setInput(value.inputSource)} />
                </div>
            {/if}
        {/each}
    </div>
</Overlay>
