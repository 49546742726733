<script>
    import { Route } from "svelte-navigator";
    import PrivateRouteGuard from "./PrivateRouteGuard.svelte";

    export let path;
    export let defaultRoute = false;
</script>

{#if defaultRoute}
    <Route let:params let:location let:navigate>
        <PrivateRouteGuard let:registerFocus>
            <slot {params} {location} {navigate} {registerFocus} />
        </PrivateRouteGuard>
    </Route>
{/if}

<Route {path} let:params let:location let:navigate>
    <PrivateRouteGuard let:registerFocus>
        <slot {params} {location} {navigate} {registerFocus} />
    </PrivateRouteGuard>
</Route>
