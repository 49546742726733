<script lang="ts">
    import { Button } from "svelte-materialify";
    import SvgIcon from "./SvgIcon.svelte";

    export let icon: string;
    export let size: string = "x-large";
    let clazz = "";
    export { clazz as class };
</script>

<Button fab depressed {size} class={clazz} on:click>
    <SvgIcon path={icon} />
</Button>
