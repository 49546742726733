<script lang="ts">
  import { Slider, Switch, Select } from "svelte-materialify";
  import Entry from "./Entry.svelte";
  import Section from "./Section.svelte";
  import { settingsStore } from "../store/settings";

  const { delayApiCallsInSeconds, logLevel, mockApiCalls, retryApiCallInSeconds } = settingsStore;

  const min = 0;
  const max = 10;

  const levels = [
    { name: "DEBUG", value: "DEBUG" },
    { name: "INFO", value: "INFO" },
    { name: "WARN", value: "WARN" },
    { name: "ERROR", value: "ERROR" },
  ];
</script>

<Section title="Developer Settings">
  <Entry>
    <Switch bind:checked={$mockApiCalls} inset>Mock API calls</Switch>
  </Entry>
  <Entry>
    <Slider {min} {max} bind:value={$delayApiCallsInSeconds} thumb persistentThumb>
      Delay Mock calls in seconds</Slider
    >
  </Entry>
  <Entry>
    <Slider {min} {max} bind:value={$retryApiCallInSeconds} thumb persistentThumb>
      Delay Retry API calls in seconds</Slider
    >
  </Entry>
  <Entry>
    <Select items={levels} closeOnClick bind:value={$logLevel}>Log Level</Select>
  </Entry>
</Section>
