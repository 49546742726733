<script lang="ts">
  import FabButton from "./FabButton.svelte";

  import { devicesStore, DeviceState } from "../store/devices2";

  export let powerOnIcon: string;
  export let powerOffIcon: string = powerOnIcon;
  export let unreachableIcon: string = powerOnIcon;
  export let powerOnColor: string = "green";
  export let powerOffColor: string = "red darken-4";
  export let commandSentColor: string = "amber darken-1";
  export let unreachableColor: string = "purple";
  export let name: string;
  export let retryDelayInSeconds: number;
  export let retryDelayInSecondsPowerOn: number = retryDelayInSeconds;

  let status = devicesStore.getStatus(name, retryDelayInSeconds, retryDelayInSecondsPowerOn);

  let allowClicks = false;
  let color = powerOnColor;
  $: {
    if ($status == DeviceState.POWER_ON) {
      color = powerOnColor;
      allowClicks = true;
    } else if ($status == DeviceState.POWER_ON_SENT || $status == DeviceState.POWER_OFF_SENT) {
      color = commandSentColor;
    } else if ($status == DeviceState.POWER_OFF) {
      color = powerOffColor;
      allowClicks = true;
    } else {
      color = unreachableColor;
    }
  }

  let path: string;
  $: {
    if ($status == DeviceState.POWER_ON || $status == DeviceState.POWER_ON_SENT) {
      path = powerOnIcon;
    } else if ($status == DeviceState.POWER_OFF || $status == DeviceState.POWER_OFF_SENT) {
      path = powerOffIcon;
    } else {
      path = unreachableIcon;
    }
  }

  function powerToggle() {
    allowClicks = false;
    devicesStore.powerToggle(name);
  }
</script>

{#if allowClicks}
  <FabButton icon={path} size="default" class={color} on:click={() => powerToggle()} />
{:else}
  <FabButton icon={path} size="default" class={color} />
{/if}
