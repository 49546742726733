<script lang="ts">
    //works differently with SvelteSkit https://stackoverflow.com/questions/66637632/access-url-query-string-in-svelte
    const urlParams = new URLSearchParams(window.location.search);
    const title = urlParams.get("title");
    const text = urlParams.get("text");
    const url = urlParams.get("url");
</script>

<div>title = {title}</div>
<div>text = {text}</div>
<div>url = {url}</div>
