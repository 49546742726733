<script lang="ts">
  import { Router, Route } from "svelte-navigator";
  import { MaterialApp } from "svelte-materialify";

  import AppBar from "./components/AppBar.svelte";
  import Main from "./pages/Main.svelte";
  import Settings from "./pages/Settings.svelte";
  import Share from "./pages/Share.svelte";

  import { settingsStore } from "./store/settings";

  import { Routes } from "./routes/routes";
  import PrivateRoute from "./components/PrivateRoute.svelte";

  const { theme } = settingsStore;
</script>

<MaterialApp theme={$theme}>
  <Router>
    <header>
      <AppBar />
    </header>

    <main>
      <PrivateRoute path={Routes.MAIN} defaultRoute={true}><Main /></PrivateRoute>
      <PrivateRoute path={Routes.SHARE}><Share /></PrivateRoute>
      <Route path={Routes.SETTINGS} primary={false}><Settings /></Route>
    </main>
  </Router>
</MaterialApp>
