<script lang="ts">
  import { Switch, TextField, Icon, Container } from "svelte-materialify";
  import Entry from "../components/Entry.svelte";
  import Section from "../components/Section.svelte";
  import DevSettings from "../components/DevSettings.svelte";

  import { mdiEyeOff, mdiEye, mdiContentSave } from "@mdi/js";

  import { settingsStore, Theme } from "../store/settings";
  import { get } from "svelte/store";
  import FabButton from "../components/FabButton.svelte";

  const { productive, version, token, theme } = settingsStore;

  let showToken = false;
  let currentToken = get(token);

  function updateToken() {
    token.set(currentToken);
  }

  $: isDarkMode = $theme == Theme.DARK;
</script>

<Container fluid>
  <Section title="Settings">
    <Entry>
      <Switch checked={isDarkMode} inset on:change={() => settingsStore.toggleTheme()}>
        Dark Mode</Switch
      >
    </Entry>
    <Entry>
      <TextField type={showToken ? "text" : "password"} bind:value={currentToken}>
        API Token
        <div slot="append">
          <FabButton
            icon={showToken ? mdiEyeOff : mdiEye}
            size="small"
            on:click={() => {
              showToken = !showToken;
            }}
          />
          <FabButton icon={mdiContentSave} size="small" on:click={() => updateToken()} />
        </div>
      </TextField>
    </Entry>
  </Section>
  {#if !productive}
    <DevSettings />
  {/if}
  <div class="d-flex justify-end"><small>Version {version}</small></div>
</Container>
