<script lang="ts">
  import { Icon } from "svelte-materialify";

  /** classes added to the icon */
  // export let class: string = undefined;
  /** size of the icon */
  export let size: string | number = "24px";
  /** degress by which to rotate the icon */
  export let rotate: number = undefined;
  /** makes the icon spin */
  export let spin: boolean = undefined;
  /** disables the icon */
  export let disabled: boolean = undefined;
  /** svg path for the icon */
  export let path: string;
  /** label for the icon */
  export let label: string = undefined;
  /** styles added to the icon */
  export let style: string = undefined;

  let svgPath: string = undefined;
  let svg: string = undefined;

  $: {
    if (path[0] == "<") {
      svgPath = undefined;
      svg = path;
      if (svg.indexOf('width="') > 0) {
        svg = svg.replace(/ width="[^"]+" /, ' width="' + size + '" ');
        svg = svg.replace(/ height="[^"]+" /, ' height="' + size + '" ');
      } else {
        svg = svg.replace(/ viewBox="/, ' width="' + size + '" height="' + size + '" viewBox="');
      }
    } else {
      svgPath = path;
      svg = undefined;
    }
  }
</script>

<Icon {size} {rotate} {spin} {disabled} {label} {style} path={svgPath}>
  {#if svg}
    {@html svg}
  {/if}
</Icon>
