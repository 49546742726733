import { log } from "../util/utils";
import { get, writable } from "svelte/store";
import { HIDDEN } from "./visibility";
import { getDeviceStatus, devicePowerOff, devicePowerOn } from "../api/api";
export var DeviceState;
(function (DeviceState) {
    DeviceState[DeviceState["POWER_ON"] = 0] = "POWER_ON";
    DeviceState[DeviceState["POWER_OFF"] = 1] = "POWER_OFF";
    DeviceState[DeviceState["UNREACHABLE"] = 2] = "UNREACHABLE";
    DeviceState[DeviceState["POWER_ON_SENT"] = 3] = "POWER_ON_SENT";
    DeviceState[DeviceState["POWER_OFF_SENT"] = 4] = "POWER_OFF_SENT";
})(DeviceState || (DeviceState = {}));
const PERIOD_VISIBLE = 5000;
const PERIOD_NOT_VISIBLE = 300000;
class DevicesStore {
    constructor() {
        this.devices = new Map();
    }
    getDeviceStatus(name, devices) {
        log.debug("DevicesStore::getStatus(" + name + ")");
        getDeviceStatus({ name })
            .then((status) => {
            const state = status.ordinal;
            log.debug("DevicesStore::getStatus(" + name + ") => ", { status, name: status.name, state });
            if (status.ordinal === DeviceState.UNREACHABLE.valueOf()) {
                devices.get(name).set(DeviceState.UNREACHABLE);
            }
            else if (status.ordinal === DeviceState.POWER_ON_SENT.valueOf()) {
                devices.get(name).set(DeviceState.POWER_ON_SENT);
            }
            else if (status.ordinal === DeviceState.POWER_OFF_SENT.valueOf()) {
                devices.get(name).set(DeviceState.POWER_OFF_SENT);
            }
            else if (status.ordinal === DeviceState.POWER_OFF.valueOf()) {
                if (get(devices.get(name)).valueOf() !== DeviceState.POWER_ON_SENT.valueOf()) {
                    devices.get(name).set(DeviceState.POWER_OFF);
                }
            }
            else if (status.ordinal === DeviceState.POWER_ON.valueOf()) {
                if (get(devices.get(name)).valueOf() !== DeviceState.POWER_OFF_SENT.valueOf()) {
                    devices.get(name).set(DeviceState.POWER_ON);
                }
            }
            log.debug("DevicesStore::getStatus(" + name + ") => " + get(devices.get(name)));
        })
            .catch((error) => {
            log.warn(error);
        });
    }
    getStatus(name, retryDelayInSeconds, retryDelayInSecondsPowerOn) {
        if (!this.devices.has(name)) {
            this.devices.set(name, writable(DeviceState.UNREACHABLE));
            HIDDEN.subscribe(hidden => {
                let delay = PERIOD_VISIBLE;
                if (get(this.devices.get(name)).valueOf() === DeviceState.POWER_ON.valueOf()) {
                    delay = retryDelayInSecondsPowerOn;
                }
                else {
                    delay = retryDelayInSeconds;
                }
                clearTimeout(this.timer);
                this.getDeviceStatus(name, this.devices);
                let timeout = hidden ? PERIOD_NOT_VISIBLE : delay * 1000;
                log.debug(name + ": hidden changed, setting timeout to " + timeout);
                this.timer = setInterval(this.getDeviceStatus, timeout, name, this.devices);
            });
        }
        return this.devices.get(name);
    }
    async powerOn(name) {
        log.debug("DevicesStore::powerOn(" + name + ")");
        this.devices.get(name).set(DeviceState.POWER_ON_SENT);
        await devicePowerOn({ name });
        this.devices.get(name).set(DeviceState.POWER_ON);
    }
    async powerOff(name) {
        log.debug("DevicesStore::powerOff(" + name + ")");
        this.devices.get(name).set(DeviceState.POWER_OFF_SENT);
        await devicePowerOff({ name });
        this.devices.get(name).set(DeviceState.POWER_OFF);
    }
    async powerToggle(name) {
        log.debug("DevicesStore::powerToggle(" + name + ")");
        if (get(this.devices.get(name)) == DeviceState.POWER_OFF) {
            await this.powerOn(name);
        }
        else if (get(this.devices.get(name)) == DeviceState.POWER_ON) {
            await this.powerOff(name);
        }
    }
}
// Export a singleton
export const devicesStore = new DevicesStore();
