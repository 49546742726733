<script>
    import { ProgressCircular } from "svelte-materialify";
    import { useNavigate, useLocation, useFocus } from "svelte-navigator";
    import { heartbeat } from "../api/api";
    import { Routes } from "../routes/routes";
    import FabButton from "./FabButton.svelte";
    import { mdiAlertCircle, mdiKey } from "@mdi/js";

    const navigate = useNavigate();
    const location = useLocation();
    const registerFocus = useFocus();

    const navigateToSettings = () => {
        navigate(Routes.SETTINGS, {
            state: { from: $location.pathname },
            replace: true,
        });
    };
</script>

{#await heartbeat()}
    <div class="center-me">
        <ProgressCircular size={150} indeterminate />
    </div>
{:then}
    <slot {registerFocus} />
{:catch response}
    {#if response.status === 511}
        <div class="center-me">
            <FabButton icon={mdiKey} on:click={() => navigateToSettings()} />
        </div>
    {:else}
        <div class="center-me">
            <FabButton icon={mdiAlertCircle} on:click={() => window.location.reload(false)} />
        </div>
    {/if}
{/await}

<style>
    .center-me {
        position: absolute;
        top: 50%;
        left: 50%;
        -moz-transform: translateX(-50%) translateY(-50%);
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
    }
</style>
