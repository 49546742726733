<script lang="ts">
  import { Button } from "svelte-materialify";

  import {
    mdiVolumeMinus,
    mdiVolumePlus,
    mdiVolumeOff,
    mdiArrowLeft,
    mdiArrowRightBold,
    mdiArrowLeftBold,
    mdiArrowUpBold,
    mdiArrowDownBold,
    mdiCircleDouble,
  } from "@mdi/js";

  import TvInput from "../components/TvInput.svelte";
  import TvButton from "../components/TvButton.svelte";
  import { TvKey } from "../store/tv";
</script>

<div class="d-flex justify-center" style="margin-top: 130px">
  <TvButton key={TvKey.UP_KEY} icon={mdiArrowUpBold} />
</div>
<div class="d-flex justify-center">
  <TvButton key={TvKey.LEFT_KEY} icon={mdiArrowLeftBold} />
  <TvButton key={TvKey.OK} icon={mdiCircleDouble} class="ml-2 mr-2" />
  <TvButton key={TvKey.RIGHT_KEY} icon={mdiArrowRightBold} />
</div>
<div class="d-flex justify-center">
  <TvButton key={TvKey.PREVIOUS_KEY} icon={mdiArrowLeft} />
  <TvButton key={TvKey.DOWN_KEY} icon={mdiArrowDownBold} class="ml-2 mr-2" />
  <Button fab depressed size="x-large" disabled style="visibility: hidden" />
</div>
<div class="d-flex justify-end" style="margin-top: 20px">
  <TvInput />
  <TvButton key={TvKey.VOLUME_UP} icon={mdiVolumePlus} class="ml-2 mr-2" />
</div>
<div class="d-flex justify-end">
  <TvButton key={TvKey.MUTE} icon={mdiVolumeOff} />
  <TvButton key={TvKey.VOLUME_DOWN} icon={mdiVolumeMinus} class="ml-2 mr-2" />
</div>

<!-- <style>
  .center-me {
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }
  .bottom-right-me {
    position: absolute;
    top: auto;
    left: auto;
    bottom: 0;
    right: 0;
  }
</style> -->
