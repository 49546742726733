import { log } from "../util/utils";
import { PRODUCTIVE, VERSION } from "./environment";
import { get, writable } from "svelte/store";
import { persist, localStorage, sessionStorage, } from "../util/storage";
export var Theme;
(function (Theme) {
    Theme["LIGHT"] = "light";
    Theme["DARK"] = "dark";
})(Theme || (Theme = {}));
class SettingsStore {
    constructor() {
        this._initialized = writable(false);
        if (PRODUCTIVE) {
            this._backendUrl = "/rpc";
        }
        else {
            this._backendUrl = "http://" + window.location.hostname + ":42042/rpc";
        }
        this.piIntervalInSeconds = this.createDevSetting("dev::piIntervalInSeconds", 300, 300);
        this.nasIntervalInSeconds = this.createDevSetting("dev::nasIntervalInSeconds", 300, 30);
        this.tvIntervalInSeconds = this.createDevSetting("dev::tvIntervalInSeconds", 300, 30);
        this.retryApiCallInSeconds = this.createDevSetting("dev::retryApiCallInSeconds", 3, 3);
        this.delayApiCallsInSeconds = this.createDevSetting("dev::delayApiCallsInSeconds", 2, 0);
        this.mockApiCalls = this.createDevSetting("dev::mockApiCalls", true, false);
        this.logLevel = this.createDevSetting("dev::logLevel", "DEBUG", "INFO");
        this.logLevel.subscribe((value) => {
            log.setDefaultLevel(value);
        });
        this._theme = persist(writable(Theme.DARK), localStorage(), "style:theme");
        this._tokenValidated = writable(false);
        this._validToken = writable(false);
        this.token = persist(writable(null), localStorage(), "token");
        this._initialized.set(true);
        log.info("Settings initialized");
    }
    updateTokenValidity(valid) {
        log.info("updateTokenValidity", valid);
        this._validToken.set(valid);
        this._tokenValidated.set(true);
    }
    createDevSetting(key, defaultDevValue, defaultProdValue) {
        if (PRODUCTIVE) {
            return persist(writable(defaultProdValue), sessionStorage(), key);
        }
        return persist(writable(defaultDevValue), localStorage(), key);
    }
    get initialized() {
        return this._initialized;
    }
    get productive() {
        return PRODUCTIVE;
    }
    get version() {
        return VERSION;
    }
    get backendUrl() {
        return this._backendUrl;
    }
    get theme() {
        return this._theme;
    }
    get isTokenValid() {
        return this._validToken;
    }
    get isTokenValidated() {
        return this._tokenValidated;
    }
    toggleTheme() {
        if (get(this._theme) == Theme.LIGHT) {
            this._theme.set(Theme.DARK);
        }
        else {
            this._theme.set(Theme.LIGHT);
        }
    }
}
// Export a singleton
export const settingsStore = new SettingsStore();
