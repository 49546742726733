<script lang="ts">
  import { Card, CardTitle, CardText, Col, Row, Container } from "svelte-materialify";

  export let title;
</script>

<Row>
  <Col
    cols={12}
    sm={10}
    offset_sm={1}
    md={8}
    offset_md={2}
    lg={6}
    offset_lg={3}
    xl={4}
    offset_xl={4}
  >
    <Card outlined>
      <CardTitle>{title}</CardTitle>
      <CardText>
        <Container fluid>
          <slot />
        </Container>
      </CardText>
    </Card>
  </Col>
</Row>
