<script lang="ts">
  import { navigate } from "svelte-navigator";

  import {
    AppBar,
    Button,
    Icon,
    List,
    ListItem,
    NavigationDrawer,
    Overlay,
  } from "svelte-materialify";

  import {
    mdiMovieOpen,
    mdiNas,
    mdiCog,
    mdiLightbulbAlertOutline,
    mdiLightbulbOffOutline,
    mdiLightbulbOutline,
    mdiPump,
    mdiPumpOff,
    mdiLightningBoltCircle,
  } from "@mdi/js";

  import Device from "./Device.svelte";
  import Device2 from "./Device2.svelte";
  import { Routes } from "../routes/routes";
  import { settingsStore } from "../store/settings";

  const { isTokenValid } = settingsStore;

  let active = false;
  function toggleNavigation() {
    active = !active;
  }

  function navigateAndToggle(to: string) {
    navigate(to);
    toggleNavigation();
  }
</script>

<AppBar class="pt-1">
  <div slot="icon">
    <!-- <Button fab depressed on:click={() => navigate("/")}> -->
    <Button fab depressed on:click={toggleNavigation}>
      <Icon path={mdiMovieOpen} />
    </Button>
  </div>
  <span slot="title">playa</span>
  <div style="flex-grow:1" />
  {#if $isTokenValid}
    <Device2
      powerOnIcon={mdiPump}
      powerOffIcon={mdiPumpOff}
      name="Pool.Pump"
      retryDelayInSeconds={10}
      retryDelayInSecondsPowerOn={60}
    />
    <div style="flex-grow:1" />
    <Device2
      powerOnIcon={mdiLightbulbOutline}
      powerOffIcon={mdiLightbulbOffOutline}
      unreachableIcon={mdiLightbulbAlertOutline}
      name="LivingRoom.Lamp"
      retryDelayInSeconds={10}
      retryDelayInSecondsPowerOn={60}
    />
    <div style="flex-grow:1" />
    <Device icon={mdiNas} name="zeus" retryDelayInSeconds={10} retryDelayInSecondsAvailable={60} />
  {/if}
</AppBar>
<NavigationDrawer absolute {active}>
  <List nav>
    <ListItem />
    <ListItem on:click={() => navigateAndToggle(Routes.MAIN)}>
      <span slot="prepend">
        <Icon path={mdiMovieOpen} />
      </span>
      TV
    </ListItem>
    <ListItem on:click={() => navigateAndToggle(Routes.SETTINGS)}>
      <span slot="prepend">
        <Icon path={mdiCog} />
      </span>
      Settings
    </ListItem>
  </List>
</NavigationDrawer>
<Overlay {active} absolute on:click={toggleNavigation} index={1} />
