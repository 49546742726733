import { log } from "../util/utils";
import { get, writable } from "svelte/store";
import { HIDDEN } from "./visibility";
import { socketAvailable, wakeOnLan } from "../api/api";
// import { TvInputSource, tvStore } from "./tv";
export var DeviceState;
(function (DeviceState) {
    DeviceState[DeviceState["OFFLINE"] = 0] = "OFFLINE";
    DeviceState[DeviceState["ONLINE"] = 1] = "ONLINE";
    DeviceState[DeviceState["POWER_ON_SENT"] = 2] = "POWER_ON_SENT";
    DeviceState[DeviceState["POWER_OFF_SENT"] = 3] = "POWER_OFF_SENT";
})(DeviceState || (DeviceState = {}));
const PERIOD_VISIBLE = 5000;
const PERIOD_NOT_VISIBLE = 300000;
class DevicesStore {
    constructor() {
        this.devices = new Map();
    }
    isAvailable(name, devices) {
        log.debug("DevicesStore::isAvailable(" + name + ")");
        socketAvailable({ address: { name } })
            .then((online) => {
            if (online) {
                devices.get(name).set(DeviceState.ONLINE);
            }
            else {
                if (get(devices.get(name)) != DeviceState.POWER_ON_SENT) {
                    devices.get(name).set(DeviceState.OFFLINE);
                }
            }
        })
            .catch((error) => {
            log.warn(error);
        });
    }
    isOnline(name, retryDelayInSeconds, retryDelayInSecondsAvailable) {
        if (!this.devices.has(name)) {
            this.devices.set(name, writable(DeviceState.OFFLINE));
            HIDDEN.subscribe(hidden => {
                let delay = PERIOD_VISIBLE;
                if (get(this.devices.get(name)) == DeviceState.ONLINE) {
                    delay = retryDelayInSecondsAvailable;
                }
                else {
                    delay = retryDelayInSeconds;
                }
                clearTimeout(this.timer);
                this.isAvailable(name, this.devices);
                let timeout = hidden ? PERIOD_NOT_VISIBLE : delay * 1000;
                log.debug(name + ": hidden changed, setting timeout to " + timeout);
                this.timer = setInterval(this.isAvailable, timeout, name, this.devices);
            });
        }
        return this.devices.get(name);
    }
    async wakeUp(name) {
        log.debug("DevicesStore::wakeUp(" + name + ")");
        this.devices.get(name).set(DeviceState.POWER_ON_SENT);
        if (name == "tv") {
            // await tvStore.setInputSource(TvInputSource.HDMI_3);
            // await power({ address: { name } });
            log.warn("wakeUp must not be called with tv");
        }
        else {
            await wakeOnLan({ address: { name } });
        }
    }
}
// Export a singleton
export const devicesStore = new DevicesStore();
