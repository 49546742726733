import { log } from "../util/utils";
import { writable } from "svelte/store";
const _HIDDEN = writable(false);
// Based on the tutorial at https://developer.mozilla.org/en-US/docs/Web/Guide/User_experience/Using_the_Page_Visibility_API
(function () {
    "use strict";
    // Set the name of the "hidden" property and the change event for visibility
    var hidden, visibilityChange;
    if (typeof document.hidden !== "undefined") {
        hidden = "hidden";
        visibilityChange = "visibilitychange";
    }
    else if (typeof document.mozHidden !== "undefined") {
        // Firefox up to v17
        hidden = "mozHidden";
        visibilityChange = "mozvisibilitychange";
    }
    else if (typeof document.webkitHidden !== "undefined") {
        // Chrome up to v32, Android up to v4.4, Blackberry up to v10
        hidden = "webkitHidden";
        visibilityChange = "webkitvisibilitychange";
    }
    function handleVisibilityChange() {
        if (document[hidden]) {
            log.debug("visibility changed to >hidden<");
            _HIDDEN.set(true);
        }
        else {
            log.debug("visibility changed to >visible<");
            _HIDDEN.set(false);
        }
    }
    // Warn if the browser doesn't support addEventListener or the Page Visibility API
    if (typeof document.addEventListener === "undefined" ||
        typeof document[hidden] === "undefined") {
        log.error("the browser does not support the Page Visibility API.");
    }
    else {
        // Handle page visibility change
        document.addEventListener(visibilityChange, handleVisibilityChange, false);
    }
})();
export const HIDDEN = _HIDDEN;
