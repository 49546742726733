<script lang="ts">
  import FabButton from "./FabButton.svelte";

  import { devicesStore, DeviceState } from "../store/devices";

  export let icon: string;
  export let onlineIcon: string = null;
  export let name: string;
  export let retryDelayInSeconds: number;
  export let retryDelayInSecondsAvailable: number;

  let online = devicesStore.isOnline(name, retryDelayInSeconds, retryDelayInSecondsAvailable);

  let color = "";
  $: {
    if ($online == DeviceState.ONLINE) {
      color = "";
    } else if ($online == DeviceState.POWER_ON_SENT) {
      color = "amber darken-1";
    } else {
      color = "red darken-4";
    }
  }

  let path: string;
  $: {
    if (onlineIcon && $online == DeviceState.ONLINE) {
      path = onlineIcon;
    } else {
      path = icon;
    }
  }

  function wakeUp() {
    if ($online != DeviceState.ONLINE) {
      devicesStore.wakeUp(name);
    }
  }
</script>

<FabButton icon={path} size="default" class={color} on:click={() => wakeUp()} />
