<script lang="ts">
    import FabButton from "./FabButton.svelte";
    import { tvStore, TvKey } from "../store/tv";

    export let icon: string;
    export let key: TvKey = null;
    let clazz = "";
    export { clazz as class };

    const isChangingSource = tvStore.isChangingSource();

    let allowClicks = true;
    let color = "";
    $: {
        allowClicks = !$isChangingSource;
    }

    function sendKey() {
        allowClicks = false;
        color = "grey";
        tvStore.sendKey(key).finally(() => (color = ""));
    }
</script>

{#if allowClicks}
    <FabButton {icon} class={clazz} on:click={() => sendKey()} />
{:else}
    <FabButton {icon} class="{clazz} {color}" />
{/if}
