import { log } from "../util/utils";
import { writable } from "svelte/store";
import { mdiAlert, mdiPower, mdiKodi, mdiNetflix, mdiYoutube, mdiMovie, mdiDiscPlayer } from "@mdi/js";
import { iconPrime } from "../util/icons";
import { HIDDEN } from "./visibility";
import { jsonRpc } from "../api/jsonrpc";
import { getId, mocked } from "../api/api";
import { devicesStore, DeviceState } from "./devices";
import { settingsStore } from "./settings";
const DEVICE_NAME = "tv";
export var TvInputSource;
(function (TvInputSource) {
    TvInputSource["UNKNOWN"] = "UNKNOWN";
    TvInputSource["TV_OFF"] = "TV_OFF";
    TvInputSource["HDMI_1"] = "HDMI_1";
    TvInputSource["HDMI_2"] = "HDMI_2";
    TvInputSource["HDMI_3"] = "HDMI_3";
    TvInputSource["HDMI_4"] = "HDMI_4";
    TvInputSource["CABLE"] = "CABLE";
    TvInputSource["NETFLIX"] = "NETFLIX";
    TvInputSource["YOUTUBE"] = "YOUTUBE";
    TvInputSource["AMAZON_PRIME"] = "AMAZON_PRIME";
    TvInputSource["USB"] = "USB";
    TvInputSource["COMPONENT"] = "COMPONENT";
    TvInputSource["AV1"] = "AV1";
    TvInputSource["AV2"] = "AV2";
})(TvInputSource || (TvInputSource = {}));
export const INPUT_SOURCES = {
    UNKOWN: {
        icon: mdiAlert,
        inputSource: TvInputSource.UNKNOWN,
        color: "yellow",
        selectable: false,
    },
    HDMI_4: {
        icon: mdiDiscPlayer,
        inputSource: TvInputSource.HDMI_4,
        color: "blue",
        selectable: true,
    },
    HDMI_2: {
        icon: mdiMovie,
        inputSource: TvInputSource.HDMI_2,
        color: "green",
        selectable: true,
    },
    AMAZON_PRIME: {
        icon: iconPrime,
        inputSource: TvInputSource.AMAZON_PRIME,
        color: "blue",
        selectable: true,
    },
    YOUTUBE: {
        icon: mdiYoutube,
        inputSource: TvInputSource.YOUTUBE,
        color: "red",
        selectable: true,
    },
    TV_OFF: {
        icon: mdiPower,
        inputSource: TvInputSource.TV_OFF,
        color: "grey darken-3",
        selectable: true,
    },
    HDMI_3: {
        icon: mdiKodi,
        inputSource: TvInputSource.HDMI_3,
        color: "blue",
        selectable: true,
    },
    NETFLIX: {
        icon: mdiNetflix,
        inputSource: TvInputSource.NETFLIX,
        color: "red darken-1",
        selectable: true,
    },
};
export var TvKey;
(function (TvKey) {
    TvKey["POWER"] = "POWER";
    TvKey["UP_KEY"] = "UP_KEY";
    TvKey["DOWN_KEY"] = "DOWN_KEY";
    TvKey["LEFT_KEY"] = "LEFT_KEY";
    TvKey["RIGHT_KEY"] = "RIGHT_KEY";
    TvKey["OK"] = "OK";
    TvKey["PREVIOUS_KEY"] = "PREVIOUS_KEY";
    TvKey["VOLUME_UP"] = "VOLUME_UP";
    TvKey["VOLUME_DOWN"] = "VOLUME_DOWN";
    TvKey["MUTE"] = "MUTE";
})(TvKey || (TvKey = {}));
async function getCurrentInput() {
    if (await mocked()) {
        return TvInputSource.NETFLIX;
    }
    let request = { address: { name: DEVICE_NAME } };
    let result = await jsonRpc(getId(request, "getCurrentInput"), "com.seifrox.micro.tv.api.TvGetCurrentInputRequest", request);
    log.debug(result);
    return result.inputSource;
}
async function setCurrentInput(inputSource) {
    if (await mocked()) {
        return TvInputSource.AMAZON_PRIME;
    }
    let request = { address: { name: DEVICE_NAME } };
    request["inputSource"] = inputSource;
    let result = await jsonRpc(getId(request, "setCurrentInput"), "com.seifrox.micro.tv.api.TvSetCurrentInputRequest", request);
    log.debug(result);
    return result.inputSource;
}
async function _sendKey(key) {
    if (await mocked()) {
        return;
    }
    let request = { address: { name: DEVICE_NAME } };
    request["key"] = key;
    return jsonRpc(getId(request, "sendKey"), "com.seifrox.micro.tv.api.TvSendKeyRequest", request);
}
const PERIOD_VISIBLE = 10000;
const PERIOD_NOT_VISIBLE = 300000;
class TvStore {
    constructor() {
        this.changingSource = writable(true);
        this.inputSource = writable(TvInputSource.UNKNOWN);
        settingsStore.isTokenValid.subscribe(valid => {
            if (valid) {
                this.online = devicesStore.isOnline(DEVICE_NAME, 10, 30);
                this.online.subscribe(state => {
                    if (state == DeviceState.OFFLINE || state == DeviceState.POWER_ON_SENT) {
                        clearTimeout(this.timer);
                        this.inputSource.set(TvInputSource.TV_OFF);
                        this.changingSource.set(false);
                    }
                    else if (state == DeviceState.ONLINE) {
                        this.inputSource.set(TvInputSource.UNKNOWN);
                        HIDDEN.subscribe(hidden => {
                            clearTimeout(this.timer);
                            this.fetchInputSource(this.inputSource, this.changingSource);
                            let timeout = hidden ? PERIOD_NOT_VISIBLE : PERIOD_VISIBLE;
                            log.debug("tv: hidden changed, setting timeout to " + timeout);
                            this.timer = setInterval(this.fetchInputSource, timeout, this.inputSource, this.changingSource);
                        });
                    }
                });
            }
        });
    }
    getInputSource() {
        return this.inputSource;
    }
    isChangingSource() {
        return this.changingSource;
    }
    async setInputSource(inputSource) {
        this.changingSource.set(true);
        this.inputSource.set(inputSource);
        await setCurrentInput(inputSource)
            .then((result) => {
            log.debug(result);
            this.inputSource.set(result);
        })
            .catch((error) => {
            log.debug(error);
            this.inputSource.set(TvInputSource.UNKNOWN);
        })
            .finally(() => {
            this.changingSource.set(false);
        });
    }
    async sendKey(key) {
        this.changingSource.set(true);
        await _sendKey(key)
            .finally(() => {
            this.changingSource.set(false);
        });
    }
    fetchInputSource(inputSource, changingSource) {
        log.debug("TvStore::fetchInputSource()");
        getCurrentInput()
            .then((result) => {
            log.debug(result);
            inputSource.set(result);
        })
            .catch((error) => {
            log.debug(error);
            inputSource.set(TvInputSource.TV_OFF);
        })
            .finally(() => {
            changingSource.set(false);
        });
    }
}
// Export a singleton
export const tvStore = new TvStore();
